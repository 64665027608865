<div class="modal__header">
  <h1 mat-dialog-title>
    {{data.header}}
  </h1>
  <span class="material-icons" (click)="exitDialog()">close</span>
</div>
<div class="modal__content" mat-dialog-content>
  <!-- <div class="listContent" cdkDropList [cdkDropListData]="columnsList" (cdkDropListDropped)="drop($event)">
    <div class="checkbox" *ngFor="let column of columnsList; let i = index"  cdkDrag>
      <mat-checkbox  [(ngModel)]="column.checked"> {{column.label}} </mat-checkbox>
    </div>
  </div> -->
  <div class="listContent" cdkDropList [cdkDropListData]="columnsList" (cdkDropListDropped)="drop($event)">
    <ng-container *ngFor="let column of columnsList; let i = index"  >
      <div class="checkbox"  *ngIf="column.showToEdit" cdkDrag>
        <mat-checkbox [(ngModel)]="column.checked"> {{column.label}} <span class="material-icons">drag_indicator</span></mat-checkbox>
      </div>
    </ng-container>
  </div>

</div>
<div class="modal__footer mat_buttons" mat-dialog-actions>
  <button mat-raised-button color="default" (click)="exitDialog()">Zamknij</button>
  <button mat-raised-button type="submit" (click)="saveColumns()" color="primary">Zapisz</button>
</div>
