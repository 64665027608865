<div class="modal__header">
  <h1 mat-dialog-title >
    {{data.header}}
  </h1>
  <span class="material-icons" (click)="exitDialog()">close</span>
</div>
<div class="modal__content statusModal" mat-dialog-content>
  <form [formGroup]="formOptions" (ngSubmit)="editRole()" #formDirective="ngForm">
    <div class="row">
      <div class="col-12">
        <mat-form-field>
          <input matInput type="text" formControlName="name" placeholder="Nazwa">
          <mat-error *ngIf="formOptions.controls['name'].errors?.required">
            Pole wymagane</mat-error>
        </mat-form-field>
        <mat-form-field>
            <input matInput [ngxMatColorPicker]="pickerColor" formControlName="color_status">
            <ngx-mat-color-toggle matSuffix [for]="pickerColor"></ngx-mat-color-toggle>
            <ngx-mat-color-picker #pickerColor ></ngx-mat-color-picker>
            <mat-error *ngIf="formOptions.controls['color_status'].errors?.required">
            Pole wymagane</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div class="modal__footer mat_buttons" mat-dialog-actions>
  <button mat-raised-button color="default" (click)="exitDialog()">Zamknij</button>
  <button mat-raised-button type="submit" [disabled]="formOptions.invalid" (click)="formDirective.ngSubmit.emit()"
    color="primary">Zapisz</button>
</div>
