<div class="modal__header">
  <h1 mat-dialog-title>
    {{data.header}}
  </h1>
  <span class="material-icons" (click)="exitDialog()">close</span>
</div>
<div class="modal__content statusModal" mat-dialog-content>
  <form [formGroup]="formOptions" (ngSubmit)="editRegulation()" #formDirective="ngForm">
    <div class="row">
      <div class="col-12">
        <mat-form-field>
          <input matInput type="text" formControlName="name" placeholder="Nazwa">
          <mat-error *ngIf="formOptions.controls['name'].errors?.required">
            Pole wymagane</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="text" formControlName="link_pl" placeholder="Link do regulaminu pl">
          <mat-error *ngIf="formOptions.controls['link_pl'].errors?.required">
            Pole wymagane</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="text" formControlName="link_en" placeholder="Link do regulaminu en">
          <mat-error *ngIf="formOptions.controls['link_en'].errors?.required">
            Pole wymagane</mat-error>
        </mat-form-field>
        <mat-checkbox class="example-margin" formControlName="active">Regulamin aktywny</mat-checkbox>
      </div>
    </div>
  </form>
</div>
<div class="modal__footer mat_buttons" mat-dialog-actions>
  <button mat-raised-button color="default" (click)="exitDialog()">Zamknij</button>
  <button mat-raised-button type="submit" [disabled]="formOptions.invalid" (click)="formDirective.ngSubmit.emit()"
    color="primary">Zapisz</button>
</div>
