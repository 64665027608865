<section class="section" id="addOrder">
  <div class="container">
    <h2>Dodaj zamówienie</h2>
    <div class="white_block editOrder">
      <button mat-raised-button color="primary" routerLink="/orders">
        <mat-icon>keyboard_arrow_left</mat-icon> Wstecz
      </button>
    </div>
    <div class="white_block">
      <div class="row">
        <div class="col-12">
          <mat-form-field class="searchProduct">
            <input
              matInput
              type="text"
              placeholder="Wybierz produkt"
              name="product"
              matInput
              [(ngModel)]="productValue"
              (ngModelChange)="changeNameTraining($event)"
              [matAutocomplete]="selectTraining"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #selectTraining="matAutocomplete"
              (optionSelected)="getDefaultTicketTraining($event.option.value)"
            >
              <mat-option
                *ngFor="let training of filteredOptions"
                [disabled]="training.published == 0 ? 'disabled' : null"
                [value]="training.name_pl"
              >
                {{ training.kod }} - {{ training.name_pl }}
              </mat-option>
            </mat-autocomplete>
            <mat-error
              *ngIf="formOptions.controls['training_id'].errors?.required"
            >
              Pole wymagane</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <form
        id="ngForm"
        [formGroup]="formOptions"
        (ngSubmit)="saveNewOrder(formDirective)"
        #formDirective="ngForm"
      >
        <div class="row">
          <div class="col-12">
            <!-- <mat-form-field>
              <mat-select
                formControlName="training_id"
                placeholder="Wybierz produkt"
              >
                <mat-option
                  *ngFor="let training of trainingList"
                  [value]="training.id"
                  >{{ training.name_pl }}</mat-option
                >
              </mat-select>
            </mat-form-field> -->

            <ng-container *ngIf="formOptions.value.training_id">
              <mat-form-field
                *ngIf="trainingDateList.length > 0; else noActiveEventDate"
              >
                <mat-select
                  formControlName="trainingDate_id"
                  (selectionChange)="changeTrainingDate($event.value)"
                  placeholder="Wybierz termin wydarzenia"
                >
                  <mat-option
                    *ngFor="let trainingDate of trainingDateList"
                    [value]="trainingDate.id"
                    >{{ trainingDate.idTermTraining }} |
                    {{ trainingDate.datestart | date: "dd MMM" }} -
                    {{ trainingDate.dateend | date: "dd MMM yyyy" }} |
                    {{ trainingDate.lang }}</mat-option
                  >
                </mat-select>
                <mat-error
                  *ngIf="
                    formOptions.controls['trainingDate_id'].errors?.required
                  "
                >
                  Pole wymagane</mat-error
                >
              </mat-form-field>
              <ng-template #noActiveEventDate>
                <p>Brak aktywnych wydarzeń dla tego produktu</p>
              </ng-template>
            </ng-container>
            <ng-container *ngIf="formOptions.value.trainingDate_id">
              <mat-form-field *ngIf="ticketList.length; else noTickets">
                <mat-select
                  formControlName="ticketID"
                  placeholder="Wybierz pakiet cenowy"
                >
                  <mat-option
                    *ngFor="let ticket of ticketList"
                    [value]="ticket.id"
                    >{{ ticket.name_pl }} {{ ticket["price_" + activeCurrency]
                    }}{{ activeCurrency }} (dostępnych
                    {{ ticket.useLimit - ticket.isUse }}szt)</mat-option
                  >
                </mat-select>
                <mat-error
                  *ngIf="formOptions.controls['ticketID'].errors?.required"
                >
                  Pole wymagane</mat-error
                >
              </mat-form-field>
              <ng-template #noTickets>
                <p>
                  Brak pakietów cenowych przypisanych do danego terminu
                  wydarzenia
                </p>
              </ng-template>
            </ng-container>

            <ng-container *ngIf="formOptions.value.ticketID">
              <mat-form-field>
                <input
                  matInput
                  type="text"
                  placeholder="Wyszukaj deal z HS"
                  formControlName="dealsID"
                  [matAutocomplete]="autoCompleteDeal"
                />
                <button
                  *ngIf="formOptions.get('dealsID').value"
                  type="button"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="clearDealsInput()"
                >
                  <mat-icon>close</mat-icon>
                </button>
                <mat-autocomplete
                  autoActiveFirstOption
                  #autoCompleteDeal="matAutocomplete"
                  (optionSelected)="matAutocompileEvent($event.option.value)"
                >
                  <mat-option
                    *ngIf="isOpitonsLoading"
                    value=""
                    class="is-loading"
                    >Loading...</mat-option
                  >
                  <ng-container *ngIf="!isOpitonsLoading">
                    <mat-option
                      *ngFor="let option of filteredDealsOptions"
                      [value]="option.properties.dealname"
                      (onSelectionChange)="dealChoise(option)"
                    >
                      {{ option.properties.dealname }} |
                      {{ option.properties.amount }} PLN
                    </mat-option>
                  </ng-container>

                  <mat-option
                    *ngIf="
                      !isOpitonsLoading && filteredDealsOptions.length == 0
                    "
                    value=""
                    class="is-loading"
                    >Brak wyników</mat-option
                  >
                </mat-autocomplete>
              </mat-form-field>
              <mat-form-field *ngIf="availableCurrency.length > 0">
                <mat-select
                  formControlName="currency"
                  placeholder="Wybierz walutę"
                >
                  <mat-option
                    *ngFor="let currency of availableCurrency"
                    [value]="currency"
                    >{{ currency }}</mat-option
                  >
                </mat-select>
                <mat-error
                  *ngIf="formOptions.controls['currency'].errors?.required"
                >
                  Pole wymagane</mat-error
                >
              </mat-form-field>

              <mat-form-field>
                <mat-select
                  formControlName="formLang"
                  placeholder="Wybierz język korespondencji"
                >
                  <mat-option value="pl">Polski</mat-option>
                  <mat-option value="en">Angielski</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="formOptions.controls['formLang'].errors?.required"
                >
                  Pole wymagane</mat-error
                >
              </mat-form-field>

              <ng-container *ngIf="activeTraining.is_exam_available">
                <mat-checkbox
                  class="example-margin"
                  formControlName="take_exam"
                  (change)="summaryPrice()"
                >
                  Zamawiam egzamin ({{
                    activeTraining["price_exam_" + activeCurrency]
                  }}
                  {{ activeCurrency }} netto)
                  <span *ngIf="activeTraining.is_exam_required">
                    Egzamin wymagany</span
                  >
                </mat-checkbox>
              </ng-container>
              <ng-container *ngIf="activeTraining.is_book_available">
                <mat-checkbox
                  class="example-margin"
                  formControlName="take_book"
                >
                  Zamawiam podręcznik ({{
                    activeTraining["price_book_" + activeCurrency]
                  }}
                  {{ activeCurrency }} netto)
                  <span *ngIf="activeTraining.is_exam_required">
                    Podręcznik wymagany</span
                  >
                </mat-checkbox>
              </ng-container>
              <ng-container *ngIf="activeTraining.is_Take2_available">
                <mat-checkbox
                  class="example-margin"
                  formControlName="take_take2"
                >
                  Zamawiam opcję Take² Re-sit ({{
                    activeTraining["price_Take2_exam_" + activeCurrency]
                  }}
                  {{ activeCurrency }} netto)
                </mat-checkbox>
              </ng-container>
              <div class="checkBoxContent">
                <mat-checkbox
                  class="example-margin"
                  formControlName="isCompanyPayer"
                  (change)="changePayPerson()"
                  >Płatnikiem jest Firma</mat-checkbox
                >
                <mat-error
                  *ngIf="
                    formOptions.controls['isCompanyPayer'].errors?.required
                  "
                  >Pole wymagane</mat-error
                >
              </div>
              <div class="checkBoxContent">
                <mat-checkbox
                  class="example-margin"
                  formControlName="isPersonPayer"
                  (change)="changePayPerson()"
                >
                  Płatnikiem jest os. fizyczna
                </mat-checkbox>
                <mat-error
                  *ngIf="formOptions.controls['isPersonPayer'].errors?.required"
                  >Pole wymagane</mat-error
                >
              </div>

              <ng-container *ngIf="formOptions.value.isCompanyPayer">
                <div class="innputText">
                  <mat-form-field>
                    <input
                      matInput
                      type="text"
                      name="VAT_ID"
                      formControlName="companyVatID"
                      placeholder="NIP"
                    />
                    <mat-error
                      *ngIf="
                        formOptions.controls['companyVatID'].errors?.required
                      "
                    >
                      Pole wymagane</mat-error
                    >
                  </mat-form-field>
                  <button
                    mat-raised-button
                    type="button"
                    color="primary"
                    (click)="getGUSData()"
                  >
                    Pobierz z GUS
                  </button>
                </div>
                <mat-form-field>
                  <input
                    matInput
                    type="text"
                    name="company"
                    formControlName="companyName"
                    placeholder="Firma"
                  />
                  <mat-error
                    *ngIf="formOptions.controls['companyName'].errors?.required"
                  >
                    Pole wymagane</mat-error
                  >
                </mat-form-field>
                <mat-form-field>
                  <input
                    matInput
                    type="text"
                    name="Street"
                    formControlName="companyStreet"
                    placeholder="Ulica"
                  />
                  <mat-error
                    *ngIf="
                      formOptions.controls['companyStreet'].errors?.required
                    "
                  >
                    Pole wymagane</mat-error
                  >
                </mat-form-field>
                <mat-form-field>
                  <input
                    matInput
                    type="text"
                    name="City"
                    formControlName="companyCity"
                    placeholder="Miasto"
                  />
                  <mat-error
                    *ngIf="formOptions.controls['companyCity'].errors?.required"
                  >
                    Pole wymagane</mat-error
                  >
                </mat-form-field>
                <mat-form-field>
                  <input
                    matInput
                    type="text"
                    name="Post code"
                    formControlName="companyPostCode"
                    placeholder="Kod pocztowy"
                  />
                  <mat-error
                    *ngIf="
                      formOptions.controls['companyPostCode'].errors?.required
                    "
                  >
                    Pole wymagane</mat-error
                  >
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="formOptions.value.isPersonPayer">
                <mat-form-field>
                  <input
                    matInput
                    type="text"
                    name="Street"
                    formControlName="personStreet"
                    placeholder="Ulica"
                  />
                  <mat-error
                    *ngIf="
                      formOptions.controls['personStreet'].errors?.required
                    "
                  >
                    Pole wymagane</mat-error
                  >
                </mat-form-field>
                <mat-form-field>
                  <input
                    matInput
                    type="text"
                    name="City"
                    formControlName="personCity"
                    placeholder="Miasto"
                  />
                  <mat-error
                    *ngIf="formOptions.controls['personCity'].errors?.required"
                  >
                    Pole wymagane</mat-error
                  >
                </mat-form-field>
                <mat-form-field>
                  <input
                    matInput
                    type="text"
                    name="Post code"
                    formControlName="personPostCode"
                    placeholder="Kod pocztowy"
                  />
                  <mat-error
                    *ngIf="
                      formOptions.controls['personPostCode'].errors?.required
                    "
                  >
                    Pole wymagane</mat-error
                  >
                </mat-form-field>
              </ng-container>
            </ng-container>

            <!-- dodawanie osób do zamówienia  -->
            <ng-container *ngIf="formOptions.value.ticketID">
              <div class="orderPerson">
                <h3><strong>Przypisz osoby do zamówienia</strong></h3>
                <div class="addPerson">
                  <div class="innputText">
                    <mat-form-field>
                      <input
                        matInput
                        type="text"
                        placeholder="Wyszukaj z HS"
                        formControlName="searchPersonInHS"
                        (input)="onSearchChange($event.target.value)"
                        [matAutocomplete]="autoCompletePerson"
                      />
                      <mat-autocomplete
                        autoActiveFirstOption
                        #autoCompletePerson="matAutocomplete"
                      >
                        <mat-option
                          *ngFor="let option of filteredContactOptions"
                          [value]="
                            option.properties.firstname?.value +
                            ' ' +
                            option.properties.lastname?.value
                          "
                          (onSelectionChange)="onEnter(option)"
                        >
                          {{ option.properties.firstname?.value }}
                          {{ option.properties.lastname?.value }} (email:
                          {{ option.properties.email?.value }})
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <button
                      mat-raised-button
                      type="button"
                      color="primary"
                      (click)="addPersontoList()"
                    >
                      Dodaj
                    </button>
                  </div>

                  <!-- *ngIf="personList.length == 0" -->
                  <mat-checkbox
                    class="example-margin"
                    formControlName="isReportingPerson"
                    >Osoba zgłaszająca</mat-checkbox
                  >
                  <mat-checkbox
                    class="example-margin"
                    formControlName="isParticipant"
                    >Uczestnik wydarzenia</mat-checkbox
                  >
                </div>
                <div class="personList">
                  <div
                    class="personBox"
                    *ngFor="let person of personList; let i = index"
                  >
                    <h4>
                      {{ person.firstName }}
                      {{ person.lastName }} (<ng-container
                        *ngIf="person.isReportingPerson"
                        >Os. zgłaszająca</ng-container
                      ><ng-container
                        *ngIf="person.isReportingPerson && person.isParticipant"
                      >
                        |
                      </ng-container>
                      <ng-container *ngIf="person.isParticipant"
                        >Uczestnik</ng-container
                      >)
                    </h4>
                    <span
                      *ngIf="!person.isReportingPerson"
                      (click)="removePersonList(i)"
                      class="material-icons"
                      >highlight_off</span
                    >
                  </div>
                </div>
                <div class="noNameTicket" *ngIf="personList.length > 0">
                  <mat-form-field>
                    <mat-label>Ilość biletów bezimiennych</mat-label>
                    <input
                      matInput
                      type="number"
                      placeholder="Ilość biletów"
                      formControlName="noNameTickets"
                      [value]="0"
                    />
                  </mat-form-field>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="personList.length > 0 && orderPriceNetto > 0">
              <div class="summary">
                <h3><strong>Podsumowanie</strong></h3>
                <mat-list role="list">
                  <mat-list-item class="listItem" role="listitem">
                    <p><strong>Imię i Nazwisko</strong></p>
                    <p><strong>Cena za wydarzenie</strong></p>
                    <p *ngIf="newOrder.take_exam > 0">
                      <strong>Cena egzamin</strong>
                    </p>
                    <p *ngIf="newOrder.take_book > 0">
                      <strong>Cena podręcznik</strong>
                    </p>
                    <p *ngIf="newOrder.take_take2 > 0">
                      <strong>Cena Take² Re-sit</strong>
                    </p>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                  <ng-container
                    *ngFor="let person of personList; let i = index"
                  >
                    <mat-list-item *ngIf="person.isParticipant" role="listitem">
                      <p>{{ person.firstName }} {{ person.lastName }}</p>
                      <p>
                        {{ activeTicket["price_" + activeCurrency] }}
                        {{ activeCurrency }}
                      </p>
                      <p *ngIf="newOrder.take_exam > 0">
                        {{ activeTraining["price_exam_" + activeCurrency] }}
                        {{ activeCurrency }}
                      </p>
                      <p *ngIf="newOrder.take_book > 0">
                        {{ activeTraining["price_book_" + activeCurrency] }}
                        {{ activeCurrency }}
                      </p>
                      <p *ngIf="newOrder.take_take2 > 0">
                        {{
                          activeTraining["price_Take2_exam_" + activeCurrency]
                        }}
                        {{ activeCurrency }}
                      </p>
                    </mat-list-item>
                    <mat-divider *ngIf="person.isParticipant"></mat-divider>
                  </ng-container>
                  <ng-container *ngIf="formOptions.value.noNameTickets > 0">
                    <mat-list-item role="listitem">
                      <p>
                        Bilety bezimienne x{{ formOptions.value.noNameTickets }}
                      </p>
                      <p>
                        {{ activeTicket["price_" + activeCurrency] }}
                        {{ activeCurrency }}
                      </p>
                      <p *ngIf="newOrder.take_exam > 0">
                        {{ activeTraining["price_exam_" + activeCurrency] }}
                        {{ activeCurrency }}
                      </p>
                      <p *ngIf="newOrder.take_book > 0">
                        {{ activeTraining["price_book_" + activeCurrency] }}
                        {{ activeCurrency }}
                      </p>
                      <p *ngIf="newOrder.take_take2 > 0">
                        {{
                          activeTraining["price_Take2_exam_" + activeCurrency]
                        }}
                        {{ activeCurrency }}
                      </p>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </ng-container>
                </mat-list>
                <div class="totalPrice">
                  <p class="text-right">
                    Całkowity koszt: {{ orderPriceNetto }}
                    {{ activeCurrency }} netto | {{ orderPriceBrutto }}
                    {{ activeCurrency }} brutto
                  </p>
                  <div class="saveBtn text-right">
                    <button
                      type="submit"
                      form="ngForm"
                      mat-raised-button
                      color="primary"
                      matTooltip="Zapisz zamówienie"
                      matTooltipPosition="below"
                    >
                      Zapisz zamówienie
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="spinner_center fixed" *ngIf="isLoadingDetails">
    <mat-spinner></mat-spinner>
  </div>
</section>
