<div class="modal__header">
  <h1 mat-dialog-title>
    {{ data.header }}
  </h1>
  <span class="material-icons" (click)="exitDialog()">close</span>
</div>
<div class="modal__content" id="addPerson" mat-dialog-content>
  <form
    [formGroup]="formOptions"
    (ngSubmit)="addNewPerson()"
    #formDirective="ngForm"
  >
    <mat-form-field
      class="example-full-width"
      *ngIf="!personTicket || personTicket?.personHS_id == 0"
    >
      <input
        type="text"
        placeholder="Znajdź osobę"
        aria-label="Number"
        (input)="onSearchChange($event.target.value)"
        matInput
        formControlName="getPerson"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option
          *ngFor="let option of filteredOptions; let i = index"
          value="{{ option.properties.firstname?.value }} {{
            option.properties.lastname?.value
          }}"
          (onSelectionChange)="onEnter(option['vid'])"
        >
          {{ option.properties.firstname?.value }}
          {{ option.properties.lastname?.value }} (email:
          {{ option.properties.email?.value }})
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="formOptions.controls['getPerson'].errors?.required">
        Pole wymagane</mat-error
      >
      <mat-error *ngIf="formOptions.controls['getPerson'].errors?.minlength">
        Minimum 4 znaki</mat-error
      >
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <mat-label>Wybierz bilet</mat-label>
      <mat-select formControlName="ticketUse_id">
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let ticket of tickets" [value]="ticket.id">
          {{ ticket.name_pl }} {{ ticket["price_" + order.currency] }}
          {{ order.currency }} (dostępne
          {{ ticket.useLimit - ticket.isUse }}szt)
        </mat-option>
      </mat-select>
      <mat-error *ngIf="formOptions.controls['ticketUse_id'].errors?.required">
        Pole wymagane</mat-error
      >
    </mat-form-field>
    <mat-checkbox *ngIf="training.is_exam_available" formControlName="take_exam"
      >Egzamin - {{ training["price_exam_" + order.currency] }}
      {{ order.currency }}&nbsp;
      <span *ngIf="training.is_exam_required">
        (Egzamin wymagany)</span
      ></mat-checkbox
    >
    <mat-checkbox *ngIf="training.is_book_available" formControlName="take_book"
      >Podręcznik - {{ training["price_book_" + order.currency] }}
      {{ order.currency }}&nbsp;
      <span *ngIf="training.is_book_required">
        (Podręcznik wymagany)</span
      ></mat-checkbox
    >
    <mat-checkbox
      *ngIf="training.is_Take2_available"
      formControlName="take_take2"
      >Opcja take2 re-sit -
      {{ training["price_Take2_exam_" + order.currency] }}
      {{ order.currency }}</mat-checkbox
    >
  </form>
</div>
<div class="modal__footer mat_buttons" mat-dialog-actions>
  <button mat-raised-button color="default" (click)="exitDialog()">
    Zamknij
  </button>
  <button
    *ngIf="!personTicket"
    mat-raised-button
    type="submit"
    color="primary"
    (click)="addNewPerson()"
  >
    Dodaj
  </button>
  <button
    *ngIf="personTicket"
    mat-raised-button
    type="submit"
    color="primary"
    (click)="editPersonTicket()"
  >
    Edytuj
  </button>
</div>
<div class="spinner_center fixed" *ngIf="invoiceLoading">
  <mat-spinner></mat-spinner>
</div>
