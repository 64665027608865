<mat-nav-list>
  <h2><strong>Czy zastosować autouzupełnianie</strong></h2>
  <a href="#" mat-list-item (click)="openLink($event, true)">
    <span mat-line>Tak</span>
    <span mat-line>Uzupełnij formularz przykładowymi danymi</span>
  </a>

  <a href="#" mat-list-item (click)="openLink($event, false)">
    <span mat-line>Nie</span>
    <span mat-line>Nie uzupełniaj formularza przykładowymi danymi</span>
  </a>
</mat-nav-list>
