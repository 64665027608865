<div class="modal__header">
  <h1 mat-dialog-title>
    {{data.header}}
  </h1>
  <span class="material-icons" (click)="exitDialog()">close</span>
</div>
<div class="modal__content langModal" mat-dialog-content>
  <form [formGroup]="formOptions" (ngSubmit)="editStatus()" #formDirective="ngForm">
    <div class="row">
      <div class="col-12">
        <mat-form-field>
          <input matInput type="text" formControlName="name_pl" placeholder="Pełna nazwa języka (PL)">
          <mat-error *ngIf="formOptions.controls['name_pl'].errors?.required">
            Pole wymagane</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="text" formControlName="name_en" placeholder="Pełna nazwa języka (EN)">
          <mat-error *ngIf="formOptions.controls['name_en'].errors?.required">
            Pole wymagane</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="text" formControlName="short_name" placeholder="Nazwa skrócona">
          <mat-error *ngIf="formOptions.controls['short_name'].errors?.required">
            Pole wymagane</mat-error>
        </mat-form-field>
        <mat-checkbox class="example-margin" formControlName="active">Lang active</mat-checkbox>
      </div>
    </div>
    <div class="editLast">
        <p *ngIf="data.lang?.userEdit">Edytowane przez: {{data.lang?.userEdit?.first_name}}
          {{data.lang?.userEdit?.last_name}}
          {{data.lang?.updated_at | date:"dd-MM-yyyy HH:mm"}}</p>
        <p *ngIf="data.lang?.userCreate">Dodane przez: {{data.lang?.userCreate?.first_name}} {{data.lang?.userCreate?.last_name}} {{data.lang?.created_at | date:"dd-MM-yyyy HH:mm"}}</p>
    </div>
  </form>
</div>
<div class="modal__footer mat_buttons" mat-dialog-actions>
  <button mat-raised-button color="default" (click)="exitDialog()">Zamknij</button>
  <button mat-raised-button type="submit" [disabled]="formOptions.invalid" (click)="formDirective.ngSubmit.emit()"
    color="primary">Zapisz</button>
</div>
