<section class="section" id="method">
  <div class="container-fluid">
    <div class="eventOption">
      <div class="dateEventSelect"></div>
      <div>
        <!-- [routerLink]="['edit']" -->
        <div class="addEvent" [routerLink]="['details']"  >
          <p>Dodaj nową metode <mat-icon>add_circle</mat-icon></p>
        </div>
        <div class="searchEvent">
            <input (keyup)="applyFilter($event.target.value)" [(ngModel)]="searchPlace" placeholder="Szukaj">
            <mat-icon>search</mat-icon>
        </div>
        <div class="refreshList" matTooltip="Odśwież listę" matTooltipPosition="left" (click)="ngOnInit()">
          <span class="material-icons">refresh</span>
        </div>
      </div>
    </div>
    <div class="listTraining" *ngIf="!methodLoading">
      <mat-table [dataSource]="dataSource" >
        <!-- firstname Column -->
        <ng-container matColumnDef="namePL">
          <mat-header-cell *matHeaderCellDef> Nazwa PL</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{element.name_pl}}
            <a fxFlexFill [routerLink]="[element.id, 'details']" class="mat-row-link"></a>
          </mat-cell>
        </ng-container>
        <!-- firstname Column -->
        <ng-container matColumnDef="nameEN">
          <mat-header-cell *matHeaderCellDef> Nazwa en</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{element.name_en}}
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <mat-paginator #paginatorListMethod [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
    </div>
    <div class="spinner_center" *ngIf="methodLoading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</section>
