<mat-table class="historyTable" [dataSource]="listHistorySource">
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef> Nazwa </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <u
        (click)="showEmailVindication(element.mailMsg)"
        *ngIf="element.mailMsg; else noMsg"
      >
        <span [innerHTML]="element.comment"></span>
      </u>
      <ng-template #noMsg>
        <span [innerHTML]="element.comment"></span>
      </ng-template>

      <span class="boxMailEvent" *ngIf="element.mailsLog">
        <span
          class="material-icons"
          [matTooltip]="
            element.mailsLog.deliveredDate != 'Invalid date'
              ? 'Mail dostarczony: ' +
                (element.mailsLog.deliveredDate | date: 'dd/MM/yyyy HH:mm')
              : ''
          "
          matTooltipPosition="right"
          [ngClass]="
            element.mailsLog.deliveredDate != 'Invalid date'
              ? 'mailSuccess'
              : ''
          "
          >forward_to_inbox</span
        >
        <span
          class="material-icons"
          [matTooltip]="
            element.mailsLog.openDate != 'Invalid date'
              ? 'Mail otwarty: ' +
                (element.mailsLog.openDate | date: 'dd/MM/yyyy HH:mm')
              : ''
          "
          matTooltipPosition="right"
          [ngClass]="
            element.mailsLog.openDate != 'Invalid date' ? 'mailSuccess' : ''
          "
          >drafts</span
        >
        <span
          class="material-icons"
          [matTooltip]="
            element.mailsLog.clickDate != 'Invalid date'
              ? 'Kliknięto w treści maila: ' +
                (element.mailsLog.clickDate | date: 'dd/MM/yyyy HH:mm')
              : ''
          "
          matTooltipPosition="right"
          [ngClass]="
            element.mailsLog.clickDate != 'Invalid date' ? 'mailSuccess' : ''
          "
          >ads_click</span
        >
        <span
          *ngIf="element.mailsLog.errorDate"
          class="material-icons"
          [matTooltip]="
            element.mailsLog.errorDate != 'Invalid date'
              ? 'Odnotowano błąd: ' +
                element.mailsLog.errorMsg +
                ' ' +
                (element.mailsLog.errorDate | date: 'dd/MM/yyyy HH:mm')
              : ''
          "
          matTooltipPosition="right"
          [ngClass]="
            element.mailsLog.errorDate != 'Invalid date' ? 'mailError' : ''
          "
          >report_problem</span
        >
      </span>
    </mat-cell>
  </ng-container>
  <!-- Data start Column -->
  <ng-container matColumnDef="user">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.userCreate?.first_name }}
      {{ element.userCreate?.last_name }} ({{
        element.updated_at | date: "dd.MM.yyyy HH:mm"
      }})
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="listrHistoryColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: listrHistoryColumns"></mat-row>
</mat-table>
<mat-paginator
  #paginatorOrderHistoryList
  [pageSizeOptions]="[10, 25, 50, 100]"
></mat-paginator>

<!-- <mat-list class="messageList" *ngIf="false">
  <ng-container *ngFor="let orderH of orderHistory">
    <mat-list-item>
      <div class="listItem">
        <div class="boxMailContent">
          <p>
            <span [innerHTML]="orderH.comment"></span>
            <span class="boxMailEvent" *ngIf="orderH.mailsLog">
              <span class="material-icons"
                [matTooltip]="(orderH.mailsLog.deliveredDate != 'Invalid date') ? 'Mail dostarczony: '+ (orderH.mailsLog.deliveredDate | date: 'dd/MM/yyyy HH:mm') : ''"
                matTooltipPosition="right"
                [ngClass]="(orderH.mailsLog.deliveredDate != 'Invalid date')  ? 'mailSuccess' : ''">forward_to_inbox</span>
              <span class="material-icons"
                [matTooltip]="(orderH.mailsLog.openDate != 'Invalid date') ? 'Mail otwarty: '+ (orderH.mailsLog.openDate | date: 'dd/MM/yyyy HH:mm') : ''"
                matTooltipPosition="right"
                [ngClass]="(orderH.mailsLog.openDate != 'Invalid date')  ? 'mailSuccess' : ''">drafts</span>
              <span class="material-icons"
                [matTooltip]="(orderH.mailsLog.clickDate != 'Invalid date') ? 'Kliknięto w treści maila: '+ (orderH.mailsLog.clickDate | date: 'dd/MM/yyyy HH:mm') : ''"
                matTooltipPosition="right"
                [ngClass]="(orderH.mailsLog.clickDate != 'Invalid date')  ? 'mailSuccess' : ''">ads_click</span>
              <span *ngIf="orderH.mailsLog.errorDate" class="material-icons"
                [matTooltip]="(orderH.mailsLog.errorDate != 'Invalid date') ? 'Odnotowano błąd: ' + orderH.mailsLog.errorMsg + ' ' + (orderH.mailsLog.errorDate | date: 'dd/MM/yyyy HH:mm') : ''"
                matTooltipPosition="right"
                [ngClass]="(orderH.mailsLog.errorDate != 'Invalid date')  ? 'mailError' : ''">report_problem</span>
            </span>
          </p>
        </div>
        <p>
          {{orderH.userCreate?.first_name}} {{orderH.userCreate?.last_name}} ({{orderH.created_at | date: 'dd/MM/yyyy HH:mm'}})
        </p>
      </div>
    </mat-list-item>
    <mat-divider></mat-divider>
  </ng-container>
  <ng-container *ngIf="order.userCreate; else noUserToAdd">
    <mat-list-item>
      <div class="listItem">
        <p>
          Dodano zgłoszenie przez: {{order.userCreate.first_name}} {{order.userCreate.last_name}} - {{order.created_at |
          date: 'dd.MM.yyyy HH:mm'}}
        </p>
      </div>
    </mat-list-item>
    <mat-divider></mat-divider>
  </ng-container>
  <ng-template #noUserToAdd>
    <mat-list-item>
      <div class="listItem">
        <p>
          Zapisano zgłoszenie: {{order.created_at | date: 'dd/MM/yyyy HH:mm'}}
        </p>
      </div>
    </mat-list-item>
    <mat-divider></mat-divider>
  </ng-template>
</mat-list> -->
