<div class="modal__header">
  <h1 mat-dialog-title>
    {{data.header}}
  </h1>
  <span class="material-icons" (click)="exitDialog()">close</span>
</div>
<div class="modal__content addTicket" mat-dialog-content>
  <form [formGroup]="formOptionsTicket" (ngSubmit)="addTicketTraining()" #formDirective2="ngForm">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6">
        <mat-form-field>
          <input matInput type="text" name='nazwa miejsca pl' formControlName="name_pl" placeholder="Nazwa PL">
          <mat-error *ngIf="formOptionsTicket.controls['name_pl'].errors?.required">
            Pole wymagane</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Data od</mat-label>
          <input matInput [matDatepicker]="dp1" name='data od' disabled formControlName="date_start">
          <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
          <mat-datepicker #dp1 disabled="false"></mat-datepicker>
          <mat-error *ngIf="formOptionsTicket.controls['date_start'].errors?.required">
            Pole wymagane</mat-error>
        </mat-form-field>
        <ng-container *ngFor="let currency of currencyList">
          <mat-form-field>
            <input matInput type="number" [formControlName]="'price_'+ currency" [placeholder]="'Cena za miejsce w ' + currency">
            <mat-error *ngIf="formOptionsTicket.controls['price_'+ currency].errors?.required">
              Pole wymagane</mat-error>
          </mat-form-field>
        </ng-container>
      </div>
      <div class="col-12 col-sm-12 col-md-6">
        <mat-form-field>
          <input matInput type="text" name='nazwa biletu en' formControlName="name_en" placeholder="Nazwa EN">
          <mat-error *ngIf="formOptionsTicket.controls['name_en'].errors?.required">
            Pole wymagane</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Data do</mat-label>
          <input matInput [matDatepicker]="dp2" name='data do' disabled formControlName="date_end">
          <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
          <mat-datepicker #dp2 disabled="false"></mat-datepicker>
          <mat-error *ngIf="formOptionsTicket.controls['date_end'].errors?.required">
            Pole wymagane</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput name="limit" type="number" formControlName="useLimit" placeholder="Limit użyć">
          <mat-error *ngIf="formOptionsTicket.controls['useLimit'].errors?.required">
            Pole wymagane</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field>
          <mat-select formControlName="rabat" placeholder="Czy miejsca rabatowane?">
            <mat-option [value]="0">
              Kody rabatowe nie działają na ten typ miejsca
            </mat-option>
            <mat-option [value]="1">
              Możliwość użycia kodu rabatowego
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-sm-12">
        <mat-checkbox color="primary" formControlName="payNow">Płatność przy rejestracji</mat-checkbox>
        <mat-checkbox color="primary" formControlName="isPublic">Bilet dostępny w formularzu</mat-checkbox>
        <h3><strong>Atrbuty miejsca</strong></h3>
        <mat-checkbox color="primary" *ngFor="let tickAttr of ticketAttr" formControlName="attr_{{tickAttr.id}}"><span
            [innerHTML]="tickAttr.name_pl"></span>
        </mat-checkbox>
      </div>
      <div class="spinner_center" *ngIf="isTicketLoading">
        <mat-spinner></mat-spinner>
      </div>
    </div>
    <div class="editLast">
        <p *ngIf="ticketData.userEdit">Edytowane przez: {{ticketData.userEdit?.first_name}}
          {{ticketData.userEdit?.last_name}}
          {{ticketData.updated_at | date:"dd-MM-yyyy HH:mm"}}</p>
        <p *ngIf="ticketData.userCreate">Dodane przez: {{ticketData.userCreate?.first_name}} {{ticketData.userCreate?.last_name}} {{ticketData.created_at | date:"dd-MM-yyyy HH:mm"}}</p>
    </div>
  </form>
</div>
<div class="modal__footer mat_buttons" mat-dialog-actions>
  <button mat-raised-button type="button" *ngIf="ticketData.id && !data.copy" (click)="removeTicket()"
    color="warn">Usuń</button>
  <button mat-raised-button color="default" (click)="exitDialog()">Zamknij</button>
  <button mat-raised-button type="submit" *ngIf="!ticketData.id || data.copy" (click)="formDirective2.ngSubmit.emit()"
    color="primary">Dodaj</button>
  <button mat-raised-button type="submit" *ngIf="ticketData.id && !data.copy" (click)="editTicketTraining()"
    color="primary">Zapisz</button>
</div>
