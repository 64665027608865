<div class="modal__header">
  <h1 mat-dialog-title>
    {{data.header}}
  </h1>
  <span class="material-icons" (click)="exitDialog()">close</span>
</div>
<div class="modal__content statusModal" mat-dialog-content>
  <form [formGroup]="formOptions" (ngSubmit)="editStatus()" #formDirective="ngForm">
    <div class="row">
      <div class="col-12">
        <mat-form-field>
          <input matInput type="text" formControlName="name_pl" placeholder="Nazwa statusu (PL)">
          <mat-error *ngIf="formOptions.controls['name_pl'].errors?.required">
            Pole wymagane</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="text" formControlName="name_en" placeholder="Nazwa statusu (EN)">
          <mat-error *ngIf="formOptions.controls['name_en'].errors?.required">
            Pole wymagane</mat-error>
        </mat-form-field>
        <mat-form-field>
            <input matInput [ngxMatColorPicker]="pickerColor" formControlName="color_status">
            <ngx-mat-color-toggle matSuffix [for]="pickerColor"></ngx-mat-color-toggle>
            <ngx-mat-color-picker #pickerColor ></ngx-mat-color-picker>
            <mat-error *ngIf="formOptions.controls['color_status'].errors?.required">
            Pole wymagane</mat-error>
        </mat-form-field>
        <mat-checkbox class="example-margin" formControlName="active">Status aktywny</mat-checkbox>
      </div>
    </div>
    <div class="editLast">
        <p *ngIf="data.status?.userEdit">Edytowane przez: {{data.status?.userEdit?.first_name}}
          {{data.status?.userEdit?.last_name}}
          {{data.status?.updated_at | date:"dd-MM-yyyy HH:mm"}}</p>
        <p *ngIf="data.status?.userCreate">Dodane przez: {{data.status?.userCreate?.first_name}} {{data.status?.userCreate?.last_name}} {{data.status?.created_at | date:"dd-MM-yyyy HH:mm"}}</p>
    </div>
  </form>
</div>
<div class="modal__footer mat_buttons" mat-dialog-actions>
  <button mat-raised-button color="default" (click)="exitDialog()">Zamknij</button>
  <button mat-raised-button type="submit" [disabled]="formOptions.invalid" (click)="formDirective.ngSubmit.emit()"
    color="primary">Zapisz</button>
</div>
