<div class="modal__header">
  <h1 mat-dialog-title>
    {{ data.header }}
  </h1>
  <span class="material-icons" (click)="exitDialog()">close</span>
</div>
<div class="modal__content adminModal" mat-dialog-content>
  <form
    [formGroup]="formOptions"
    (ngSubmit)="editAdmin()"
    #formDirective="ngForm"
  >
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <mat-form-field>
          <input
            matInput
            type="text"
            name="Imię"
            formControlName="first_name"
            placeholder="Imię"
          />
          <mat-error
            *ngIf="formOptions.controls['first_name'].errors?.required"
          >
            Pole wymagane</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-6">
        <mat-form-field>
          <input
            matInput
            type="text"
            name="Nazwisko"
            formControlName="last_name"
            placeholder="Nazwisko"
          />
          <mat-error *ngIf="formOptions.controls['last_name'].errors?.required">
            Pole wymagane</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field>
          <input
            matInput
            type="text"
            name="email"
            formControlName="email"
            placeholder="Email"
          />
          <mat-error *ngIf="formOptions.controls['email'].errors?.required">
            Pole wymagane</mat-error
          >
          <mat-error *ngIf="formOptions.controls['email'].errors?.email"
            >Błędny email</mat-error
          >
        </mat-form-field>
        <mat-form-field>
          <mat-select
            formControlName="role"
            placeholder="Rola użytkownika"
            [disabled]="userData.role > 0"
          >
            <mat-option [value]="0">Administrator</mat-option>
            <mat-option [value]="1">Użytkownik</mat-option>
          </mat-select>
          <mat-error *ngIf="formOptions.controls['role'].errors?.required">
            Pole wymagane</mat-error
          >
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            type="password"
            name="hasło"
            formControlName="password"
            placeholder="Hasło"
          />
          <mat-error *ngIf="formOptions.controls['password'].errors?.required">
            Pole wymagane</mat-error
          >
        </mat-form-field>
        <mat-checkbox
          color="primary"
          class="example-margin"
          formControlName="active"
          [disabled]="userData.role > 0"
          >Użytkownik aktywny</mat-checkbox
        >
      </div>
    </div>
    <div class="editLast">
      <p *ngIf="data.user?.userEdit">
        Edytowane przez: {{ data.user?.userEdit?.first_name }}
        {{ data.user?.userEdit?.last_name }}
        {{ data.user?.updated_at | date: "dd-MM-yyyy HH:mm" }}
      </p>
      <p *ngIf="data.user?.userCreate">
        Dodane przez: {{ data.user?.userCreate?.first_name }}
        {{ data.user?.userCreate?.last_name }}
        {{ data.user?.created_at | date: "dd-MM-yyyy HH:mm" }}
      </p>
    </div>
  </form>
</div>

<div class="modal__footer mat_buttons" mat-dialog-actions>
  <button
    mat-raised-button
    color="warn"
    *ngIf="data.user && userData.role == 0"
    (click)="removeUser()"
  >
    Usuń
  </button>
  <button
    mat-raised-button
    color="default"
    (click)="changePass()"
    *ngIf="showBtnPass"
  >
    Zmień hasło
  </button>
  <button mat-raised-button color="default" (click)="exitDialog()">
    Zamknij
  </button>
  <button
    mat-raised-button
    type="submit"
    [disabled]="formOptions.invalid"
    (click)="formDirective.ngSubmit.emit()"
    color="primary"
  >
    Zapisz
  </button>
</div>
