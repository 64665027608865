<div class="modal__header">
  <h1 mat-dialog-title>
    {{data.header}} {{data.group?.name_pl}}
  </h1>
  <span class="material-icons" (click)="exitDialog()">close</span>
</div>
<div class="modal__content editGroupModal" mat-dialog-content>
  <form [formGroup]="formOptions" (ngSubmit)="editGroup()" #formDirective="ngForm">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <mat-form-field>
          <input matInput type="text" formControlName="name_pl" placeholder="Nazwa PL">
          <mat-error *ngIf="formOptions.controls['name_pl'].errors?.required">
            Pole wymagane</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="text" formControlName="anchor_pl" placeholder="Kotwica PL">
        </mat-form-field>
        <mat-form-field>
          <mat-select formControlName="parent" placeholder="Rodzic">
            <mat-option [value]="0">Brak grupy nadrzędnej</mat-option>
            <mat-option *ngFor="let gropuParent of data.groupParents" [value]="gropuParent.id">
              {{gropuParent.name_pl}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="formOptions.controls['parent'].errors?.required">
            Pole wymagane</mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-6">
        <mat-form-field>
          <input matInput type="text" formControlName="name_en" placeholder="Nazwa EN">
          <mat-error *ngIf="formOptions.controls['name_en'].errors?.required">
            Pole wymagane</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="text" formControlName="anchor_en" placeholder="Kotwica EN">
        </mat-form-field>
        <mat-form-field>
          <input matInput type="number" formControlName="rank" placeholder="Pozycja">
          <mat-error *ngIf="formOptions.controls['rank'].errors?.required">
            Pole wymagane</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-checkbox class="example-margin" formControlName="is_hide">Ukryty</mat-checkbox>
      </div>
    </div>
    <div class="editLast">
        <p *ngIf="data.group?.userEdit">Edytowane przez: {{data.group?.userEdit?.first_name}}
          {{data.group?.userEdit?.last_name}}
          {{data.group?.updated_at | date:"dd-MM-yyyy HH:mm"}}</p>
        <p *ngIf="data.group?.userCreate">Dodane przez: {{data.group?.userCreate?.first_name}} {{data.group?.userCreate?.last_name}} {{data.group?.created_at | date:"dd-MM-yyyy HH:mm"}}</p>
    </div>
  </form>
</div>
<div class="modal__footer mat_buttons" mat-dialog-actions>
  <button mat-raised-button color="warn" (click)="removeGroup()">Usuń</button>
  <button mat-raised-button color="default" (click)="exitDialog()">Zamknij</button>
  <button mat-raised-button type="submit" [disabled]="formOptions.invalid" (click)="formDirective.ngSubmit.emit()"
    color="primary">Zapisz</button>
</div>
