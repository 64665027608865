<section class="section" id="trainingDetails">
  <div class="container">
    <h2 *ngIf="idTraining > 0">Edytuj produkt: {{ training.name_pl }}</h2>
    <h2 *ngIf="idTraining == 0">Dodaj produkt</h2>
    <div class="white_block editTraining">
      <button mat-raised-button color="primary" routerLink="/trainings">
        <mat-icon>keyboard_arrow_left</mat-icon> Wstecz
      </button>
      <div>
        <button
          type="button"
          mat-raised-button
          color="warn"
          (click)="remove()"
          matTooltip="Usuń szkolenie"
          matTooltipPosition="below"
          *ngIf="idTraining > 0"
        >
          <span class="material-icons">delete_forever</span>
        </button>
        <button
          type="submit"
          form="ngForm"
          mat-raised-button
          color="primary"
          matTooltip="Zapisz zmiany"
          matTooltipPosition="below"
        >
          <span class="material-icons">save</span>
        </button>
      </div>
    </div>

    <form
      id="ngForm"
      [formGroup]="formOptions"
      (ngSubmit)="updateTraining(formDirective)"
      #formDirective="ngForm"
      *ngIf="!trainingLoading"
    >
      <mat-tab-group
        mat-stretch-tabs
        class="example-stretched-tabs mat-elevation-z4"
        dynamicHeight
      >
        <mat-tab label="Informacje ogólne">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <mat-form-field>
                  <mat-select
                    formControlName="status"
                    placeholder="Status produktu"
                  >
                    <mat-option value="NEW">NEW</mat-option>
                    <mat-option value="ACTIVE">ACTIVE</mat-option>
                    <mat-option value="ARCHIVED">ARCHIVED</mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="formOptions.controls['status'].errors?.required"
                  >
                    Pole wymagane</mat-error
                  >
                </mat-form-field>
              </div>
              <div class="col-12 col-sm-12 col-md-6">
                <mat-form-field>
                  <input
                    matInput
                    type="text"
                    formControlName="name_pl"
                    placeholder="Nazwa (PL)"
                  />
                  <mat-error
                    *ngIf="formOptions.controls['name_pl'].errors?.required"
                  >
                    Pole wymagane</mat-error
                  >
                </mat-form-field>
                <mat-form-field>
                  <input
                    matInput
                    type="text"
                    formControlName="name_short_pl"
                    placeholder="Nazwa krótka (PL)"
                  />
                  <mat-error
                    *ngIf="
                      formOptions.controls['name_short_pl'].errors?.required
                    "
                  >
                    Pole wymagane</mat-error
                  >
                </mat-form-field>
                <mat-form-field>
                  <input
                    matInput
                    type="text"
                    formControlName="alias_pl"
                    placeholder="Alias (PL)"
                  />
                  <mat-error
                    *ngIf="formOptions.controls['alias_pl'].errors?.required"
                  >
                    Pole wymagane</mat-error
                  >
                </mat-form-field>
                <!-- <mat-form-field>
                  <input matInput type="text" formControlName="file_pdf_pl" placeholder="PDF (PL)">
                </mat-form-field> -->
                <mat-form-field>
                  <input
                    matInput
                    type="text"
                    formControlName="kod"
                    placeholder="Kod szkolenia"
                  />
                  <mat-error
                    *ngIf="formOptions.controls['kod'].errors?.required"
                  >
                    Pole wymagane</mat-error
                  >
                </mat-form-field>
              </div>
              <div class="col-12 col-sm-12 col-md-6">
                <mat-form-field>
                  <input
                    matInput
                    type="text"
                    formControlName="name_en"
                    placeholder="Nazwa (EN)"
                  />
                  <mat-error
                    *ngIf="formOptions.controls['name_en'].errors?.required"
                  >
                    Pole wymagane</mat-error
                  >
                </mat-form-field>
                <mat-form-field>
                  <input
                    matInput
                    type="text"
                    formControlName="name_short_en"
                    placeholder="Nazwa krótka (EN)"
                  />
                  <mat-error
                    *ngIf="
                      formOptions.controls['name_short_en'].errors?.required
                    "
                  >
                    Pole wymagane</mat-error
                  >
                </mat-form-field>
                <mat-form-field>
                  <input
                    matInput
                    type="text"
                    formControlName="alias_en"
                    placeholder="Alias (EN)"
                  />
                  <mat-error
                    *ngIf="formOptions.controls['alias_en'].errors?.required"
                  >
                    Pole wymagane</mat-error
                  >
                </mat-form-field>
                <!-- <mat-form-field>
                  <input matInput type="text" formControlName="file_pdf_en" placeholder="PDF (EN)">
                </mat-form-field> -->
                <mat-form-field>
                  <input
                    matInput
                    type="text"
                    formControlName="gtuCode"
                    placeholder="Kod gtu produktu"
                  />
                  <mat-error
                    *ngIf="formOptions.controls['gtuCode'].errors?.required"
                  >
                    Pole wymagane</mat-error
                  >
                </mat-form-field>
              </div>
              <div class="col-12">
                <mat-form-field>
                  <mat-select formControlName="grupa_id" placeholder="Rodzic">
                    <mat-optgroup
                      *ngFor="let group of groupList"
                      [label]="group.name"
                      [disabled]="group.disabled"
                    >
                      <mat-option
                        *ngFor="let childGroup of group.children"
                        [value]="childGroup.value"
                        [disabled]="childGroup.disabled"
                        >{{ childGroup.viewValue }}</mat-option
                      >
                    </mat-optgroup>
                  </mat-select>
                  <mat-error
                    *ngIf="formOptions.controls['grupa_id'].errors?.required"
                  >
                    Pole wymagane</mat-error
                  >
                </mat-form-field>
                <!-- <mat-form-field>
                  <input matInput type="text" formControlName="logo" placeholder="Logo organizacji certyfikującej">
                </mat-form-field> -->
                <mat-form-field>
                  <mat-select
                    formControlName="formIdHS"
                    placeholder="Id formularza w HS"
                  >
                    <mat-option
                      *ngFor="let training of formsHS"
                      [value]="training.guid"
                    >
                      {{ training.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="formOptions.controls['formIdHS'].errors?.required"
                  >
                    Pole wymagane</mat-error
                  >
                </mat-form-field>
                <!-- <mat-form-field>
                  <input matInput type="text" formControlName="rank" placeholder="Pozycja">
                  <mat-error *ngIf="formOptions.controls['rank'].errors?.required">
                    Pole wymagane</mat-error>
                </mat-form-field> -->
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Egzamin">
          <mat-checkbox formControlName="is_exam_available"
            >Egzamin dostępny</mat-checkbox
          >
          <mat-checkbox formControlName="is_exam_required"
            >Egzamin wymagany</mat-checkbox
          >
          <mat-form-field>
            <mat-select
              formControlName="exam_lang"
              placeholder="Język egzaminu"
              multiple
            >
              <mat-option
                *ngFor="let lang of langList"
                [disabled]="lang.active == 0 ? 'disabled' : null"
                [value]="lang.short_name"
                >{{ lang.short_name }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="formOptions.controls['exam_lang'].errors?.required"
            >
              Pole wymagane</mat-error
            >
          </mat-form-field>
          <ng-container *ngFor="let currency of currencyList">
            <mat-form-field *ngIf="currency.active > 0">
              <input
                matInput
                type="number"
                [formControlName]="'price_exam_' + currency.name"
                [placeholder]="'Cena egzaminu w ' + currency.name"
              />
              <mat-error
                *ngIf="
                  formOptions.controls['price_exam_' + currency.name].errors
                    ?.required
                "
              >
                Pole wymagane</mat-error
              >
            </mat-form-field>
          </ng-container>
          <mat-form-field>
            <textarea
              matInput
              rows="3"
              formControlName="price_exam_desc_pl"
              placeholder="Dodatkowy opis w dymku (PL)"
            ></textarea>
          </mat-form-field>
          <mat-form-field>
            <textarea
              matInput
              rows="3"
              formControlName="price_exam_desc_en"
              placeholder="Additional info (tooltip) (EN)"
            ></textarea>
          </mat-form-field>
        </mat-tab>
        <mat-tab label="Podręcznik">
          <mat-checkbox formControlName="is_book_available"
            >Podręcznik dostępny</mat-checkbox
          >
          <mat-checkbox formControlName="is_book_required"
            >Podręcznik wymagany</mat-checkbox
          >
          <ng-container *ngFor="let currency of currencyList">
            <ng-container *ngIf="currency.active > 0">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  [formControlName]="'price_book_' + currency.name"
                  [placeholder]="'Cena podręcznika w ' + currency.name"
                />
                <mat-error
                  *ngIf="
                    formOptions.controls['price_book_' + currency.name].errors
                      ?.required
                  "
                >
                  Pole wymagane</mat-error
                >
              </mat-form-field>
            </ng-container>
          </ng-container>
          <mat-form-field>
            <input
              matInput
              type="text"
              formControlName="book_iso"
              placeholder="ISBN"
            />
          </mat-form-field>
          <mat-form-field>
            <textarea
              matInput
              rows="3"
              formControlName="book_pl"
              placeholder="Book name (PL)"
            ></textarea>
          </mat-form-field>
          <mat-form-field>
            <textarea
              matInput
              rows="3"
              formControlName="book_en"
              placeholder="Book name (EN)"
            ></textarea>
          </mat-form-field>
        </mat-tab>
        <mat-tab label="Język">
          <mat-form-field>
            <mat-select
              formControlName="lang"
              placeholder="Język prowadzonego wydarzenia"
              multiple
            >
              <mat-option
                *ngFor="let lang of langList"
                [disabled]="lang.active == 0 ? 'disabled' : null"
                [value]="lang.short_name"
                >{{ lang.short_name }}</mat-option
              >
            </mat-select>
            <mat-error *ngIf="formOptions.controls['lang'].errors?.required">
              Pole wymagane</mat-error
            >
          </mat-form-field>
          <mat-form-field>
            <mat-select
              formControlName="lang_materials"
              placeholder="Język materiałów"
              multiple
            >
              <mat-option
                *ngFor="let lang of langList"
                [disabled]="lang.active == 0 ? 'disabled' : null"
                [value]="lang.short_name"
                >{{ lang.short_name }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="formOptions.controls['lang_materials'].errors?.required"
            >
              Pole wymagane</mat-error
            >
          </mat-form-field>
        </mat-tab>
        <mat-tab label="Pakiety cenowe">
          <div class="tabTicketHead">
            <button type="button" (click)="addDefaultTicket()">
              Dodaj pakiet cenowy
              <mat-icon class="material-icons-outlined">add_circle</mat-icon>
            </button>
          </div>
          <!-- tickets list -->
          <mat-table
            #ticketListTable
            class="ticketTable"
            [dataSource]="ticketDataSource"
            matSort
            *ngIf="allDefaultTickets.length > 0; else noTickets"
          >
            <!-- firstname Column -->
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef>
                Nazwa pakietu
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.name_pl }}
              </mat-cell>
            </ng-container>
            <!-- Data start Column -->
            <!-- <ng-container matColumnDef="termValidity">
              <mat-header-cell *matHeaderCellDef>
                Ilość dni ważności biletu
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.date_start | date: "dd MMM" }} -
                {{ element.date_end | date: "dd MMM yyyy" }}
              </mat-cell>
            </ng-container> -->
            <!-- Data start Column -->
            <ng-container matColumnDef="price_PLN">
              <mat-header-cell *matHeaderCellDef> Wartość PLN </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.price_PLN | currency: "PLN":true }}
              </mat-cell>
            </ng-container>
            <!-- Data end limit użyć -->
            <ng-container matColumnDef="limit">
              <mat-header-cell *matHeaderCellDef> Limit </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.useLimit }}
              </mat-cell>
            </ng-container>
            <!-- Data end action -->
            <ng-container matColumnDef="action">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div
                  class="clickEventList"
                  (click)="editDefaultTicket(element)"
                ></div>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="ticketColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: ticketColumns"></mat-row>
          </mat-table>
          <ng-template #noTickets>
            <h4 class="text-center"><strong>Brak pakietów cenowych</strong></h4>
          </ng-template>
        </mat-tab>
        <mat-tab label="Pozostałe">
          <mat-checkbox formControlName="is_Take2_available"
            >Opcja Take2 Re-sit Exam dostępna</mat-checkbox
          >
          <ng-container *ngFor="let currency of currencyList">
            <mat-form-field *ngIf="currency.active > 0">
              <input
                matInput
                type="number"
                [formControlName]="'price_Take2_exam_' + currency.name"
                [placeholder]="
                  'Cena opcji Take2 Re-sit Exam w ' + currency.name
                "
              />
              <mat-error
                *ngIf="
                  formOptions.controls['price_Take2_exam_' + currency.name]
                    .errors?.required
                "
                >Pole wymagane</mat-error
              >
            </mat-form-field>
          </ng-container>
          <mat-checkbox formControlName="is_ecert_available"
            >Certyfikat dostępny</mat-checkbox
          >
          <mat-form-field>
            <input
              matInput
              type="text"
              formControlName="price_ecert_papier"
              placeholder="Cena certyfikatu papierowego"
            />
            <mat-error
              *ngIf="
                formOptions.controls['price_ecert_papier'].errors?.required
              "
            >
              Pole wymagane</mat-error
            >
          </mat-form-field>
          <mat-form-field>
            <mat-checkbox
              formControlName="is_blended"
              [(ngModel)]="training.is_blended"
              >Blended</mat-checkbox
            >
            <input
              matInput
              type="number"
              formControlName="price_blended"
              placeholder="Cena blended"
            />
            <mat-error
              *ngIf="formOptions.controls['price_blended'].errors?.required"
              >Pole wymagane</mat-error
            >
          </mat-form-field>
        </mat-tab>
      </mat-tab-group>
      <div class="editByblock" *ngIf="training.userEdit || training.userCreate">
        <p *ngIf="training.userEdit">
          Edytowane przez: {{ training.userEdit?.first_name }}
          {{ training.userEdit?.last_name }}
          {{ training.updated_at | date: "dd-MM-yyyy HH:mm" }}
        </p>
        <p *ngIf="training.userCreate">
          Dodane przez: {{ training.userCreate?.first_name }}
          {{ training.userCreate?.last_name }}
          {{ training.created_at | date: "dd-MM-yyyy HH:mm" }}
        </p>
      </div>
    </form>
    <div class="white_block spinner_center" *ngIf="trainingLoading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</section>
