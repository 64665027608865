<section class="section" id="trainerList">
  <div class="container-fluid">
    <div class="eventOption">
      <div class="dateEventSelect"></div>
      <div>
        <div class="addEvent" [routerLink]="['create']">
          <p>Dodaj trenera <mat-icon>add_circle</mat-icon></p>
        </div>
        <div class="searchEvent">
          <input
            (keyup)="applyFilter($event.target.value)"
            [(ngModel)]="searchTrainer"
            placeholder="Szukaj"
          />
          <mat-icon>search</mat-icon>
        </div>
        <!-- <div
          class="refreshList"
          matTooltip="Odśwież listę"
          matTooltipPosition="left"
          (click)="getTrainers()"
        >
          <span class="material-icons">refresh</span>
        </div> -->
      </div>
    </div>

    <div class="listTraining" *ngIf="!trainersLoading">
      <mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> Imię i Nazwisko</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.name }} {{ element.lastName }}
            <a
              fxFlexFill
              [routerLink]="[element.id, 'details']"
              class="mat-row-link"
            ></a>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="lastName">
          <mat-header-cell *matHeaderCellDef> Nazwisko </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.lastName }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="trainings">
          <mat-header-cell *matHeaderCellDef> Szkolenia </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <ng-container *ngFor="let training of element.trainings">
              <span>{{ training.kod }},&nbsp;</span>
            </ng-container>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginatorListTrainers
        [pageSizeOptions]="[10, 25, 50]"
      ></mat-paginator>
    </div>
    <div class="spinner_center" *ngIf="trainersLoading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</section>
